import {defineStore} from 'pinia'
import {NetworkService} from "~/utils/NetworkService";
import type {AddStudentIntoSessionRb} from "~/models/request/addStudentIntoSessionRb";
import type {Session} from "~/models/session";
import {SessionStatus} from "~/utils/enums/SessionStatus";

export const sessionStore = defineStore({
    id: 'session-store',
    persist: true,
    state: () => {
        return {
            sessions: <Session[]>[],
            sessionsForEventRegistrationCompany: <Session[]>[],
        }
    },
    actions: {
        clearSessionStore: function () {
            this.sessions = <Session[]>[];
            this.clearSessionsForEventRegistrationCompany();
        },
        clearSessionsForEventRegistrationCompany: function () {
            this.sessionsForEventRegistrationCompany = <Session[]>[];
        },
        addStudentIntoSession: async function (studentSessionRb:AddStudentIntoSessionRb, fromServer?:boolean) {
            const config = useRuntimeConfig()
            let url;
            if(fromServer){
                url = '/api/session/add-student-into-session';
            }else{
                url = config.public.BASE_URL_SOPS + '/api/sessionStudent/CreateUpdateSessionStudent';
            }
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, studentSessionRb, null, "addStudentIntoSession" + new Date().getMilliseconds() + "_")
        },
        loadAllSessionByCompanyId: async function (companyId:number) {
            try{
                const config = useRuntimeConfig()
                let url = config.public.BASE_URL_SOPS + '/api/session/GetAllSessionsByCompanyId';
                const ns = new NetworkService();
                let {Result} = await ns.post$fetch(url, {"CompanyId": companyId}, null, "getAllSessionByCompanyId" + new Date().getMilliseconds() + "_")
                if(Result){
                    for(const rawSession of Result){
                        this.sessions.push(rawSession as Session);
                    }
                }
            }catch (e) {
                console.log(e)
            }
        },
        loadAllSessionByCompanyIdServer: async function (companyId:number) {
            const tmpSessions = <Session[]>[];
            try{
                const config = useRuntimeConfig()
                let url = '/api/session/sessions-by-company-id';
                const ns = new NetworkService();
                let {Result} = await ns.post$fetch(url, {"CompanyId": companyId}, null, "loadAllSessionByCompanyIdServer" + new Date().getMilliseconds() + "_")
                if(Result){
                    for(const rawSession of Result){
                        tmpSessions.push(rawSession as Session);
                    }
                }
            }catch (e) {
                console.log(e)
            }
            return tmpSessions;
        },
        setSessionsForEventRegistrationCompany: function (sessions:Session[]){
            this.sessionsForEventRegistrationCompany = sessions;
        },
        setSessionsForCurrentCompany: function (sessions:Session[]){
            this.sessions = sessions;
        },
        loadAndSetSessionsForEventRegistrationCompany: async function(companyId:number){
            const sessions = await this.loadAllSessionByCompanyIdServer(companyId);
            this.setSessionsForEventRegistrationCompany(sessions);
        },
        loadAndSetSessionsByCompanyId: async function(companyId:number){
            const sessions = await this.loadAllSessionByCompanyIdServer(companyId);
            this.setSessionsForCurrentCompany(sessions);
        },
        getActiveSession: function () {
            return this.sessions.find((singleSession)=>singleSession.Status == SessionStatus.ACTIVE)
        },
        getSessionIdsBySessions: function (sessions:Session[]){
            return sessions.map((session)=>session.id);
        }
    },
    getters: {
        getActiveSessionOfThisCompany: state => state.sessions.find((singleSession)=>singleSession.Status == SessionStatus.ACTIVE),
        getActiveSessionsOfThisCompany: state => state.sessions.filter((singleSession)=>singleSession.Status == SessionStatus.ACTIVE),
        getActiveSessionOfThisEventRegistrationCompany: state => state.sessionsForEventRegistrationCompany.find((singleSession)=>singleSession.Status == SessionStatus.ACTIVE)
    },
})